@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;

.dashboard {
  width: 100%;
  display: flex;

  &__upcoming {
    flex: 1 1.65;
    margin-left: utils.rem(30);

    .card {
      padding: utils.rem(47) utils.rem(23);
    }
  }

  &__detail {
    flex: 1.65;
  }

  &__actions {
    display: flex;
    align-items: center;

    & + .card {
      margin-top: utils.rem(61);
    }

    .card + .card {
      margin-left: utils.rem(17);
    }
  }

  &__payments,
  &__verification-requests {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: utils.rem(9) utils.rem(54) utils.rem(15) utils.rem(40);
    height: utils.rem(147);

    &-detail {
      display: flex;
      flex-direction: column;
      padding-top: utils.rem(13);

      > h5 {
        font-size: utils.rem(22);
        font-weight: 400;
      }

      > a {
        margin-top: utils.bu(2.5);

        > label {
          color: map-get(colors.$primary-color, primary);
          font-weight: 400;
          cursor: pointer;
        }
      }
    }
  }

  &__users > header > a > label {
    color: map-get(colors.$primary-color, primary);
    font-weight: 400;
    cursor: pointer;
  }

  &__verification-requests-detail > a > label:hover {
    font-weight: 400;
  }

  &__users > header > a > label:hover {
    font-weight: 400;
  }

  &__users {
    padding: utils.rem(19) utils.rem(36) utils.rem(32) utils.rem(26);

    > header {
      display: flex;
      justify-content: space-between;

      > h5 {
        font-size: utils.rem(22);
        font-weight: 400;
      }
    }
  }

  &__stats {
    margin-top: utils.bu(8);
    display: flex;
    justify-content: space-between;
  }
}
