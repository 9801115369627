@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.organisations-list {

  .table {

    .table-heading:nth-child(1) {
      width: utils.rem(100);
    }

    .table-heading:nth-child(2) {
      width: utils.rem(239);
    }

    .table-heading:nth-child(3) {
      width: utils.rem(315);
    }

    .table-heading:nth-child(4) {
      width: utils.rem(228);
    }

    .table-heading:nth-child(5) {
      width: utils.rem(188);
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {

      .table-heading:nth-child(1) {
        width: utils.rem(150);
      }

      .table-heading:nth-child(2) {
        width: utils.rem(289);
      }

      .table-heading:nth-child(3) {
        width: utils.rem(365);
      }

      .table-heading:nth-child(4) {
        width: utils.rem(278);
      }

      .table-heading:nth-child(5) {
        width: utils.rem(238);
      }
    }
  }
}
