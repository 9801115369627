@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/extra" as extras;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.competition-details {
  position: relative;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  padding-bottom: utils.bu(2);

  &__details {
    margin-left: utils.rem(310);
    flex: 2;

    > .card + .card {
      margin-top: utils.rem(50);
    }
  }

  &__actions {
    padding: utils.rem(15) utils.rem(3);
  }

  .description-section {
    height: utils.rem(141);

    .input__input-wrapper,
    .form-row {
      margin: 0;
    }

    textarea {
      border: none;
    }
  }

  .classes-section {

    .table {
      @include mixins.set-font-family('SemplicitaPro');

      .table-cell {
        font-size: utils.rem(10);
        color: rgba(map-get(colors.$text-color, rich-black), .87);
        font-weight: 400;
      }

      .table-cell:first-child {
        font-weight: 500;
        font-size: utils.rem(14);
        color: rgba(map-get(colors.$text-color, rich-black), .87);
      }
    }
  }

  .events-section {
    margin-top: utils.rem(50);

    p {
      font-size: utils.rem(14);
      margin-top: utils.rem(10);
      margin-bottom: utils.rem(30);
      margin-left: utils.rem(34);
      @include mixins.set-font-family('SemplicitaPro');
    }

    .scroller {
      margin-left: utils.bu(1);
      margin-right: utils.bu(1);
    }
  }

  .competition-details-section {
    padding: 0 utils.bu(3) utils.bu(3);

    .form {

      .radio-group + .input,
      .input + .select,
      .select + .input,
      .select + .radio-group,
      .radio-group + .radio-group,
      .input + .input {
        margin-left: utils.rem(110);
      }

      .form-row:last-child {
        align-items: flex-start;

        .input {
          width: 50%;
        }
      }
    }
  }

  .regulation-section {
    padding: 0 utils.bu(3) utils.bu(3);
  }
}
