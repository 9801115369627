@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.users-list {

  .table {

    .table-heading:nth-child(1) {
      width: utils.rem(100);
    }

    .table-heading:nth-child(2),
    .table-heading:nth-child(3) {
      width: utils.rem(190);
    }

    .table-heading:nth-child(4) {
      width: utils.rem(283);
    }

    .table-heading:nth-child(5) {
      width: utils.rem(160);
    }

    .table-heading:nth-child(6) {
      width: utils.rem(145);
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {

      .table-heading:nth-child(1) {
        width: utils.rem(150);
      }

      .table-heading:nth-child(2),
      .table-heading:nth-child(3) {
        width: utils.rem(240);
      }

      .table-heading:nth-child(4) {
        width: utils.rem(333);
      }

      .table-heading:nth-child(5) {
        width: utils.rem(210);
      }

      .table-heading:nth-child(6) {
        width: utils.rem(195);
      }
    }
  }
}
