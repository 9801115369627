@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.tag {
  display: flex;
  align-items: center;
  height: utils.rem(28);
  box-shadow: 0 utils.rem(3) utils.rem(6) rgba(0, 0, 0, .09);
  border-radius: utils.rem(3);
  background-color: rgba(map-get(colors.$primary-color, white), .45);
  padding: utils.rem(6);
  font-size: utils.rem(14);
  margin-right: utils.bu(2);
  margin-bottom: utils.rem(10);
  @include mixins.set-font-family('SemplicitaPro');

  svg {
    cursor: pointer;
    margin-left: utils.rem(9);
    color: map-get(colors.$primary-color, primary);
  }

  &--error {
    color: map-get(colors.$primary-color, error);

    svg {
      color: map-get(colors.$primary-color, error);
    }
  }
}

.invite-admin {

  &__action {
    margin-left: auto;
    margin-right: auto;
    margin-top: utils.rem(50);
    width: utils.rem(163);
  }

  &__search {
    margin-bottom: utils.rem(20);

    & > .input {

      > .input__label {
        padding-left: 0;
      }

      > .input__input-wrapper {
        padding-left: utils.rem(17);
        border-radius: utils.rem(5);
        height: utils.rem(45);
        border: utils.rem(1) solid rgba(map-get(colors.$primary-color, black), .12);
      }
    }
  }

  &__error-msg {
    color: map-get(colors.$primary-color, error);
    margin: 0;
  }

  &__emails {
    display: flex;
    align-items: flex-start;
    height: utils.rem(111);
    margin-left: auto;
    margin-right: auto;
    background-color: map-get(colors.$background-color, gallery);
    padding: utils.rem(15) utils.bu(2);
    flex-wrap: wrap;
    overflow-y: scroll;
    border-radius: utils.rem(5);
  }
}
