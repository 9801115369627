@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;

.login {

  .checkbox {
    margin-left: utils.bu(1);
    width: 50%;
  }

  &__forgot-password {
    color: map-get(colors.$text-color, black);
  }

  .form-action {
    margin-left: auto;
    margin-right: auto;
    width: utils.rem(168);
  }
}
