@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.organisers {
  width: utils.rem(277);
  padding-top: utils.rem(34);
  padding-left: utils.rem(15);

  h4 {
    @include mixins.set-font-family('SemplicitaPro');

    font-weight: 400;
    font-size: utils.rem(14);
    color: rgba(map-get(colors.$primary-color, black), .87);
  }

  &__badges {
    margin-top: utils.rem(30);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}
