@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;

.stat-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;

  > h5 {
    font-size: utils.rem(22);
    font-weight: 400;
  }

  > small {
    margin-top: utils.rem(22);
    margin-bottom: utils.rem(15);
    max-width: utils.rem(185);
    color: rgba(map-get(colors.$text-color, tuna), .8);
  }
}
