@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;

.filter-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: utils.rem(35);

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__filters {
    display: flex;
    align-items: center;
    width: utils.rem(610);

    > .search-input {
      width: utils.rem(465);
      min-width: utils.rem(465);
    }

    > * + * {
      margin-left: utils.rem(30);
    }
  }

  &__clear {
    cursor: pointer;
    width: utils.rem(125);
    display: flex;
    align-items: center;
    color: map-get(colors.$primary-color, primary);
    font-size: utils.rem(14);

    &-filters {
      width: utils.rem(95);
      margin-left: utils.rem(10);
    }

    > svg {
      width: utils.rem(17);
      height: utils.rem(17);
    }
  }

  &__actions {
    display: flex;

    .button {
      width: utils.rem(168);
    }
  }

  &__content {
    margin-top: utils.rem(34);
  }
}
