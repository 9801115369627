@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/extra" as extras;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.event-details {
  position: relative;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  padding-bottom: utils.bu(2);

  &__details {
    margin-left: utils.rem(303);
    flex: 2;
  }

  &__actions {
    padding: utils.rem(15) utils.rem(3);
  }

  .event-details-section {
    padding: 0 utils.bu(3) utils.bu(3);

    .form {

      .radio-group + .input,
      .input + .select,
      .select + .radio-group,
      .input + .input {
        margin-left: utils.rem(110);
      }
    }
  }

  .description-section {

    .form-row:first-child {
      margin-top: 0;
    }

    textarea {
      border: none;
    }
  }

  .classes-section {

    .table {
      @include mixins.set-font-family('SemplicitaPro');

      .table-cell {
        font-size: utils.rem(10);
        color: rgba(map-get(colors.$text-color, rich-black), .87);
        font-weight: 400;
      }

      .table-cell:first-child {
        font-weight: 500;
        font-size: utils.rem(14);
        color: rgba(map-get(colors.$text-color, rich-black), .87);
      }
    }
  }
}
