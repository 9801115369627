@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;

.organisation-account-details {
  width: 100%;
  padding: utils.rem(20) utils.rem(26) utils.rem(20) utils.rem(20);

  .form {

    .input + .input {
      margin-left: utils.rem(95);
    }

    .form-row:first-child {
      margin-top: 0;
    }

    .form-row:nth-child(2) {
      align-items: flex-start;

      .input {
        width: 45.5%;
      }
    }
  }
}
