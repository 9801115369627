@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;

.personal-details {
  padding-left: utils.rem(26);
  padding-bottom: utils.bu(6);
  padding-top: utils.rem(25);
  display: flex;

  &__form {
    max-width: utils.rem(756);
    margin-left: utils.bu(8);
    flex: 2;

    .form {
      margin-top: utils.bu(7.5);

      .form-row {

        .input + .input {
          margin-left: utils.rem(52);
        }

        &:nth-child(2) {
          align-items: flex-start;

          .input {
            width: 46%;
          }
        }
      }
    }
  }
}
