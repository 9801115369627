@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/extra" as extras;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

$badge-size: utils.rem(45);
$lg-badge-size: utils.rem(88);

.badge {
  $block: &;

  flex-direction: column;
  margin-right: utils.rem(30);
  margin-bottom: utils.rem(20);
  @include mixins.flex-center();

  &__icon {
    width: $badge-size;
    height: $badge-size;
    background-color: map-get(colors.$primary-color, white);
    box-shadow: 0 utils.rem(4) utils.rem(4) rgba(0, 0, 0, .25);
    border-radius: map-get(extras.$border-radius, circular);

    > img,
    > svg {
      border-radius: map-get(extras.$border-radius, circular);
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    margin-top: utils.bu(2);
    @include mixins.set-font-family('SemplicitaPro');
  }

  &--large {
    #{$block}__icon {
      width: $lg-badge-size;
      height: $lg-badge-size;
    }
  }
}
