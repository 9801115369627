@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/extra" as extras;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;

.contact-details {
  padding: utils.rem(40) utils.rem(30) utils.rem(30) utils.rem(89);
  display: flex;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    > img,
    > svg {
      border-radius: map-get(extras.$border-radius, circular);
      height: utils.rem(155);
      width: utils.rem(155);
    }
  }

  &__form {
    max-width: utils.rem(756);
    margin-left: utils.rem(114);
    flex: 2;

    h4 {
      font-weight: 400;
      font-size: utils.rem(34);
    }

    .form {
      margin-top: utils.bu(5.625);
      width: utils.rem(776);

      .input + .input {
        margin-left: utils.rem(80);
      }

      .form-row {

        &:nth-child(2),
        &:nth-child(3) {
          align-items: flex-start;

          .input {
            width: 45%;
          }
        }
      }
    }
  }
}
