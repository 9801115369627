@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.payment-details {
  position: relative;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  padding-bottom: utils.bu(2);

  &__context {
    position: fixed;
  }

  &__details {
    margin-left: utils.rem(310);
    flex: 2;

    .details-section:first-child .card {
      padding: 0;
      margin-top: utils.rem(30);
      box-shadow: none;
      background-color: transparent;
    }

    .details-section:nth-child(3) .card {
      padding: utils.rem(30);

      .form-row:first-child {
        margin-top: 0;
      }
    }

    .details-section:last-of-type .card {
      padding: 0;
      box-shadow: none;
      background-color: transparent;
    }
  }

  &__upload {
    display: flex;
    align-items: center;
    flex-direction: column;

    > p {
      font-size: utils.rem(14);
      font-weight: 300;
      margin-bottom: utils.bu(2);
    }
  }

  &__attached-file {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-details {
      display: flex;
      align-items: center;

      > svg {
        color: map-get(colors.$primary-color, primary);
      }

      > p {
        margin-left: utils.bu(3);
        font-size: utils.rem(14);
        font-weight: 400;
        @include mixins.set-font-family('SemplicitaPro');
      }
    }

    > a {
      cursor: pointer;
      color: map-get(colors.$primary-color, primary);
      font-weight: 400;
      font-size: utils.rem(14);
      @include mixins.set-font-family('SemplicitaPro');
    }
  }

  &__total-due {
    margin-left: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: utils.rem(28) utils.rem(42) utils.rem(15);
    width: utils.rem(362);

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: utils.bu(2) 0;

      span {
        font-weight: 600;
        margin-right: utils.rem(96);
      }

      p {
        text-align: right;
      }
    }
  }

  &__actions {
    margin-top: utils.bu(5);
  }
}
