@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;

.upcoming-card {

  &__details {
    border-radius: utils.rem(10);
    border: utils.rem(1) solid rgba(map-get(colors.$primary-color, black), .12);
    padding: utils.rem(20) 0 0;
    display: flex;
    flex-direction: column;

    > label {
      color: rgba(map-get(colors.$text-color, tuna), .8);
      font-weight: 510;
      margin-left: utils.rem(23);
    }

    > small {
      color: rgba(map-get(colors.$text-color, tuna), .8);
      margin-top: utils.bu(2);
      margin-bottom: utils.rem(22);
      margin-left: utils.rem(23);
      height: utils.rem(33);
    }

    > footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: utils.rem(.5) solid rgba(map-get(colors.$text-color, tuna), .12);
      padding: utils.rem(19) utils.rem(22) utils.rem(19) utils.rem(16);

      > .button {
        text-align: left;
        width: utils.rem(180);
        font-weight: 700;
        color: rgba(map-get(colors.$primary-color, primary), .8);
      }

      > label {
        color: rgba(map-get(colors.$text-color, tuna), .8);
      }
    }

    &--empty {
      align-items: center;
      justify-content: center;
      padding-bottom: utils.rem(20);

      > small {
        margin: 0;
      }
    }
  }

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: utils.rem(20);

    > h5 {
      font-weight: 400;
    }

    > a > label {
      color: map-get(colors.$primary-color, primary);
      font-weight: 400;
      cursor: pointer;

      &:hover {
        font-weight: 510;
      }
    }
  }

  & + & {
    margin-top: utils.bu(5);
  }
}
