@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;

.details-section {

  > h5 {
    margin-bottom: utils.rem(30);
  }

  & + & {
    margin-top: utils.rem(50);
  }
}
