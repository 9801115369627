@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/extra" as extras;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.organisation-details {
  width: 100%;
  padding-bottom: utils.bu(2);

  &__split {
    display: flex;

    .card {
      height: utils.rem(257);

      &:nth-child(1) {
        max-width: utils.rem(277);
      }
    }

    .card + & {
      margin-top: utils.rem(45);
    }

    .card + .card {
      margin-left: utils.rem(61);
      flex: 2;
    }
  }
}

.verification-actions {
  margin-top: utils.bu(5);
  width: utils.rem(625);
  margin-left: auto;
  margin-right: utils.rem(160);

  .button + .button {
    margin-left: utils.rem(25);
  }
}
