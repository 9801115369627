@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.verifications-list {

  .table {

    .table-heading:nth-child(1) {
      width: utils.rem(100);
    }

    .table-heading:nth-child(2) {
      width: utils.rem(190);
    }

    .table-heading:nth-child(3) {
      width: utils.rem(266);
    }

    .table-heading:nth-child(4) {
      width: utils.rem(217);
    }

    .table-heading:nth-child(5) {
      width: utils.rem(214);
    }

    .table-heading:nth-child(6) {
      width: utils.rem(180);
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {

      .table-heading:nth-child(1) {
        width: utils.rem(150);
      }

      .table-heading:nth-child(2) {
        width: utils.rem(240);
      }

      .table-heading:nth-child(3) {
        width: utils.rem(316);
      }

      .table-heading:nth-child(4) {
        width: utils.rem(267);
      }

      .table-heading:nth-child(5) {
        width: utils.rem(264);
      }

      .table-heading:nth-child(6) {
        width: utils.rem(230);
      }
    }
  }
}
