@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.events-list {

  .table {

    .table-heading:nth-child(1) {
      width: utils.rem(100);
    }

    .table-heading:nth-child(2) {
      width: utils.rem(199);
    }

    .table-heading:nth-child(3) {
      width: utils.rem(243);
    }

    .table-heading:nth-child(4) {
      width: utils.rem(152);
    }

    .table-heading:nth-child(5) {
      width: utils.rem(170);
    }

    .table-heading:nth-child(6) {
      width: utils.rem(151);
    }

    .table-heading:nth-child(7) {
      width: utils.rem(137);
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {

      .table-heading:nth-child(1) {
        width: utils.rem(150);
      }

      .table-heading:nth-child(2) {
        width: utils.rem(249);
      }

      .table-heading:nth-child(3) {
        width: utils.rem(293);
      }

      .table-heading:nth-child(4) {
        width: utils.rem(202);
      }

      .table-heading:nth-child(5) {
        width: utils.rem(220);
      }

      .table-heading:nth-child(6) {
        width: utils.rem(201);
      }

      .table-heading:nth-child(7) {
        width: utils.rem(187);
      }
    }
  }
}
