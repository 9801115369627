@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.admin-layout {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: map-get(colors.$background-color, primary);

  > .sidebar {
    z-index: 3;

    .sidebar__items {
      margin-top: utils.bu(1.5);

      .sidebar-item {
        margin-top: utils.bu(3);
        margin-bottom: utils.bu(3);
      }
    }
  }

  &__content-section {
    background-color: map-get(colors.$background-color, primary);
    width: 100%;
    margin-left: utils.rem(100);
  }

  &__content {
    background-color: map-get(colors.$background-color, primary);
    margin-top: utils.rem(83);
    width: 100%;
    padding: utils.rem(50) utils.rem(53) utils.rem(60) utils.rem(54);
    flex-direction: column;
    @include mixins.flex-center();

    &--gutterless {
      padding-top: 0;
    }
  }
}
