@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/extra" as extras;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.user-details {
  width: 100%;

  &__split {
    display: flex;

    .card {

      &:nth-child(1) {
        flex: .8;
      }
    }

    .card + & {
      margin-top: utils.rem(45);
    }

    .card + .card {
      margin-left: utils.bu(5);
      flex: 2;
    }
  }
}
