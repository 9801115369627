@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/extra" as extras;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.profile-details {
  display: flex;
  padding: utils.bu(5);

  &__image {
    border-radius: map-get(extras.$border-radius, circular);
    width: utils.rem(155);
    height: utils.rem(155);
    padding: utils.rem(5);
    position: relative;

    &-action {
      position: absolute;
      bottom: 0;
      right: 0;
      color: map-get(colors.$primary-color, primary);
      cursor: pointer;
    }
  }

  &__form {
    margin-left: utils.bu(8);
    width: 100%;

    .form {
      padding: utils.rem(12);

      .button-group {
        width: utils.rem(275);

        > .button,
        > .form-action {
          margin-top: utils.rem(50);
        }

        > .form-action > .button {
          width: utils.rem(168);
        }
      }
    }
  }

  &__action {
    color: map-get(colors.$primary-color, primary);
    @include mixins.svg-hover();

    &--disabled {
      color: rgba(map-get(colors.$text-color, secondary), .67);
    }
  }
}
