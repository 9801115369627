@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;

.select {
  width: 100%;

  .dropdown,
  .dropdown__trigger {
    width: 100%;
  }

  .input__icon {
    color: map-get(colors.$text-color, dark-gray);
  }

  &--disabled {

    .dropdown__trigger {
      cursor: auto;
    }
  }
}
