@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.request-change-verification {
  padding-left: utils.rem(37);
  padding-right: utils.rem(37);
  @include mixins.set-font-family('SemplicitaPro');

  .text-area__text-area-wrapper {

    textarea {
      height: utils.rem(111);
      background: rgba(map-get(colors.$background-color, gallery), .5);
      box-shadow: 0 utils.rem(2) utils.rem(4) rgba(0, 0, 0, .12);
      border: none;
    }
  }

  .form-action {
    width: utils.rem(230);
    margin-left: auto;
    margin-right: auto;
  }
}
