@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;

.context-details {
  display: flex;
  position: fixed;
  flex-direction: column;
  align-items: center;

  &-image {
    width: 100%;
    margin-top: utils.bu(2);
    border-radius: utils.rem(5);

    > svg {
      width: 100%;
    }
  }

  > .card + .card {
    margin-top: utils.rem(50);
  }

  .context-card + .card:last-child {
    margin-top: utils.bu(5);
  }

  .status-blip {
    margin: utils.rem(35) auto utils.bu(2);
  }
}
