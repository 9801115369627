@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;

.expired-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__header {
    text-align: center;
  }

  &__title {
    margin-top: utils.rem(25);
    margin-bottom: utils.rem(46);
    text-align: center;
    font-weight: normal;
  }

  &__subtitle {
    text-align: center;
  }

  &__footer {
    width: utils.rem(160);
    margin-top: utils.rem(50);
  }
}
