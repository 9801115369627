@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/config" as config;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.competitions-list {

  .table {

    .table-heading:nth-child(1) {
      width: utils.rem(100);
    }

    .table-heading:nth-child(2) {
      width: utils.rem(183);
    }

    .table-heading:nth-child(3) {
      width: utils.rem(117);
    }

    .table-heading:nth-child(4) {
      width: utils.rem(151);
    }

    .table-heading:nth-child(5) {
      width: utils.rem(206);
    }

    .table-heading:nth-child(6) {
      width: utils.rem(134);
    }

    .table-heading:nth-child(7) {
      width: utils.rem(135);
    }

    .table-heading:nth-child(8) {
      width: utils.rem(126);
    }

    @include mixins.breakpoint(map-get(config.$breakpoints, large)) {

      .table-heading:nth-child(1) {
        width: utils.rem(150);
      }

      .table-heading:nth-child(2) {
        width: utils.rem(233);
      }

      .table-heading:nth-child(3) {
        width: utils.rem(167);
      }

      .table-heading:nth-child(4) {
        width: utils.rem(201);
      }

      .table-heading:nth-child(5) {
        width: utils.rem(256);
      }

      .table-heading:nth-child(6) {
        width: utils.rem(184);
      }

      .table-heading:nth-child(7) {
        width: utils.rem(185);
      }

      .table-heading:nth-child(8) {
        width: utils.rem(176);
      }
    }
  }
}
