@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;

.sport-details {
  width: 100%;

  svg {
    color: map-get(colors.$primary-color, primary);
  }

  .button {
    margin-top: utils.bu(5);
  }

  .add-action {
    margin-top: utils.bu(3);
    margin-left: utils.bu(6);
    margin-bottom: utils.rem(2);
  }

  &--editMode {

    .details-section__message {

      > svg {
        color: map-get(colors.$text-color, gray);
      }
    }
  }
}
