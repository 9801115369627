@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/01_tools/mixin" as mixins;

.auth-layout {
  width: 100%;
  height: 100%;
  background: url("../../../images/auth-background.jpg") no-repeat;
  background-size: cover;
  mix-blend-mode: overlay;
  @include mixins.flex-center();

  &__subtitle {
    width: utils.rem(425);
    text-align: center;
    color: map-get(colors.$text-color, black);
  }

  &__content {
    min-height: utils.rem(636);
    width: utils.rem(522);
    margin-left: auto;
    margin-right: utils.rem(101);
    padding: utils.rem(51) utils.rem(43);
    flex-direction: column;
    background-color: map-get(colors.$primary-color, white);
    border-radius: utils.rem(20);
    @include mixins.flex-center();

    &-header {
      margin-bottom: utils.bu(6);
      flex-direction: column;
      @include mixins.flex-center();

      > p {
        @include mixins.set-font-family('SemplicitaPro');

        font-size: utils.rem(14);
        margin-top: utils.bu(2);
        color: rgba(map-get(colors.$text-color, black), .87);
      }
    }

    &-form {
      width: utils.rem(400);
    }
  }
}
