@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/color" as colors;
@use "~@neslotech/eventhub-ui-kit/src/stylesheets/00_settings/util" as utils;

.verify-email {

  .form-action {
    margin-left: auto;
    margin-right: auto;
    width: utils.rem(168);
  }
}
